@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #F6FBFE;
}

.title {
  font-weight: 600;
  font-size: 36px;
  line-height: 110%;
  /*font-family: "Zilla Slab";*/
}

.subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  /*font-family: "Zilla Slab";*/
}

.colorbg {
  background-color: #1D3C86;
  color: white;
}

.orangeColor {
  accent-color: rgb(234 88 12);
}

.subheading {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #00137A;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  /* Adjust z-index to make sure it overlays other elements */
}


.scroll-container {
  border-left: 0px solid #23479F;
  border-right: 0px solid #23479F;
  border-bottom: 0px solid #23479F;
  max-height: 1000px;
  overflow-y: auto;
}

#pageno {
  width: 32px;
  height: 28px;
  text-align: center;
  padding: 4px 8px;
  margin-left: 8px;
  outline: none;
  border: none;
  /* border: 1px solid #d3d3d3; */
  border-radius: 2px;
  background: #f8f8f8;
}

.toolbarButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  border: none;
  background: none;
  border-radius: 2px;
  cursor: pointer;
}

#pageno {
  width: 32px;
  height: 28px;
  text-align: center;
  padding: 4px 8px;
  margin-left: 8px;
  outline: none;
  border: none;
  /* border: 1px solid #d3d3d3; */
  border-radius: 2px;
  background: #f8f8f8;
}

.toolbarButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  border: none;
  background: none;
  border-radius: 2px;
  cursor: pointer;
}

.toolbarButton::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 16px;
  height: 16px;
  mask-size: cover;
}

div.bot-response ol {
  list-style: decimal;
}

.firstCard {
  width: 20%;
}

.secondCard {
  width: 20%;
}

.thirdCard {
  width: 60%;
}

.secondcardWidth {
  width: 25%;
}

.thirdcardWidth {
  width: 75%;
}

.MainContent {
  width: 80%;
}

div.bullet ul {
  list-style: disc;
  margin: 10px 0px 20px 20px;
  padding: 0px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) { }

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) { }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .customScrollbar {
    height: 90vh;
    overflow-y: auto;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .customScrollbar {
    height: 85vh;
    overflow-y: auto;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .customScrollbar {
    height: 90vh;
    overflow-y: auto;
  }
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 400px; /* Adjust width as needed */
}

.modal-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.modal-content {
  margin-bottom: 20px;
}

.modal-close-btn {
  background: #eee;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.modal-close-btn:hover {
  background: #ddd;
}

.highlight {
  background-color: yellow;
}
.readAloud1{
  Button:nth-of-type(1){
    margin-top: 3px;
        padding: 10px;
        border-radius: 50% !important;
        height: 36px;

  }
  Button:nth-of-type(2){
    margin-top: 3px;
        padding: 10px;
        border-radius: 50% !important;
        height: 36px;

  }
 
}
.readSelected{
  Button{
    height: 36px;
    padding: 10px;

    /* width: 50px; */
    border-radius: 50%;

  }
}
.readAloud{
  width: 20px !important;
}

.fixWidth{
  width: 48rem;
}

@media screen and (max-width: 1439px) and (min-width:1325px) {
  .fixWidth{
    width: 42rem;
  } 
}

@media screen and (max-width: 1324px) and (min-width:1230px) {
  .fixWidth{
    width: 36rem;
  } 
}

@media screen and (max-width: 1230px) and (min-width:1165px) {
  .fixWidth{
    width: 32rem;
  } 
}

@media screen and (max-width: 1164px) and (min-width:1100px) {
  .fixWidth{
    width: 28rem;
  } 
}

@media screen and (max-width: 1100px) and (min-width:1035px) {
  .fixWidth{
    width: 24rem;
  } 
}

@media screen and (max-width: 1035px) and (min-width:1020px) {
  .fixWidth{
    width: 23rem;
  } 
}
